<template>
  <div :key="locale" class="sp-app-container">
    <slot />
    <ZendeskButton />
  </div>
</template>

<script setup>
import ZendeskButton from '@/components/Buttons/ZendeskButton/ZendeskButton'

const { locale } = useCurrentLocale()

const { defaultHead, defaultSeo } = useDefaultMetaData()
useHead(defaultHead())
useSeoMeta(defaultSeo())

onMounted(() => {
  nextTick(() => {
    if (!window.gtmLoaded) {
      window.gtmLoaded = true
      const gtm = useGtm()
      gtm.enable(true) // Enable GTM dynamically
    }
  })
})
</script>

<style lang="css">
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sp-app-container {
  margin: 0;
  height: auto;
  position: relative;
}
</style>
